<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          outlined
          dense
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="dialog = true">
          {{ $t("add") }}
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
      ></data-table-component>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <!-- <template v-slot:activator="{ on, attrs }">

              </template> -->
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("users.user")
                }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    hide-details
                    persistent-hint
                    v-model="editedItem.name"
                    :rules="[rules.length]"
                    :label="$t('users.userName')"
                  ></v-text-field>
                </v-col>
                <v-col v-if="editedIndex == -1" cols="12" md="6">
                  <v-text-field
                    outlined
                    hide-details
                    v-model="editedItem.password"
                    :label="$t('users.password')"
                    :rules="[rules.length]"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col v-if="editedIndex == -1" cols="12" md="6">
                  <v-text-field
                    outlined
                    hide-details
                    type="password"
                    v-model="editedItem.password_confirmation"
                    :rules="[rules.length, rules.confirmPassword]"
                    :label="$t('users.confirmPassword')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    hide-details
                    v-model="editedItem.email"
                    :rules="[rules.length, rules.email]"
                    :label="$t('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    outlined
                    hide-details
                    :items="groups"
                    v-model="editedItem.groups_id"
                    :label="$t('groups.groupName')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="schoolsGroups"
                    v-model="selectedSchoolsGroups"
                    :label="$t('schoolGroups.schoolGroups')"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-checkbox :label="$t('users.is_agent')" v-model="editedItem.is_agent" :false-value="0" :true-value="1"/>
                </v-col>
                <v-col cols="12" md="12" v-if="!editedItem.is_agent">
                  <v-select
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="directorates"
                    v-model="selectedDirectorates"
                    :label="$t('directorates.directorates')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" v-if="!editedItem.is_agent">
                  <v-select
                    outlined
                    chips
                    multiple
                    :clearable="true"
                    :deletable-chips="true"
                    hide-details
                    :items="governorates"
                    v-model="selectedGovernorates"
                    :label="$t('governorates')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" v-if="editedItem.is_agent">
                  <v-select
                    outlined
                    hide-details
                    :items="agencies"
                    v-model="editedItem.agency_id"
                    :label="$t('users.agency')"
                    item-text="title"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" class="py-0">
                  <v-card outlined style="overflow-y: auto" height="250">
                    <v-card-text>
                      <v-treeview
                        selectable
                        v-model="selectedDirectorates"
                        :items="directorates"
                        item-key="id"
                        item-text="name"
                        selected-color="primary"
                      >
                      </v-treeview>
                    </v-card-text>
                  </v-card>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              class="white--text"
              @click="save"
              :disabled="!valid || submitLoading"
              :loading="submitLoading"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="close">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    ></confirm-dialog>

    <v-dialog v-model="dialogChangePassword" persistent max-width="400px">
      <v-form ref="form1" v-model="valid1">
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class=""> {{ $t("users.changePassword") }}</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.password"
                    :label="$t('users.password')"
                    :rules="[rules.length]"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    v-model="editedItem.password_confirmation"
                    :rules="[rules.length, rules.confirmPassword]"
                    :label="$t('users.confirmPassword')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              class="white--text"
              @click="saveChangePassword"
              :disabled="!valid1 || submitLoading"
              :loading="submitLoading"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeChangePassword">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  components: {DataTableComponent, ConfirmDialog},
  data() {
    return {
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      submitLoading: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",
      users: [],
      groups: [],
      editedItem: {},
      schoolsGroups: [],
      agencies: [],
      selectedSchoolsGroups: [],
      directorates: [],
      governorates: [],
      selectedDirectorates: [],
      selectedGovernorates: [],
      defaultItem: {
        userName: "",
        email: null,
        password: "",
        governorates: [],
        confirmPassword: "",
        phoneNumber: null,
        userGroup: "",
        branchGuid: "",
        doctorGuid: "",
        groupGuid: "",
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
            this.editedItem.password === this.editedItem.password_confirmation ||
            this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
        length: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  computed: {
    headers() {
      var list = [
        {text: this.$t("users.userName"), value: "name"},
        {text: this.$t("email"), value: "email"},
        {text: this.$t("groups.groupName"), value: "groupName"},
        {text: this.$t("users.agency"), value: "agencyName"},
        {
          text: "",
          value: "actions",
          templates: [
            {
              type: "btn",
              icon: "mdi-key",
              text: this.$t("users.changePassword"),
              click: this.changePassword,
            },
            {
              type: "btn",
              icon: "mdi-pencil",
              text: this.$t("edit"),
              click: this.editItem,
            },
            {
              type: "btn",
              icon: "mdi-delete",
              text: this.$t("delete"),
              click: this.deleteItem,
              color: "red darken-2",
            },
          ],
        },
      ];

      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(1)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
    this.refreshTable();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedSchoolsGroups = this.editedItem.school_group.map(
          (a) => a.groupsch_id
      );
      this.selectedDirectorates = this.editedItem.directorates.map(
          (a) => a.directorate_id
      );
      this.selectedGovernorates = this.editedItem.governorates.map(
          (a) => a.id
      );
      this.dialog = true;
    },
    changePassword(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogChangePassword = true;
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.users[this.editedIndex];
      this.$axios
          .delete("DeleteUser/" + deleteItem.id)
          .finally(() => {
            this.refreshTable();
            this.closeDelete();
          });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selectedSchoolsGroups = [];
        this.selectedDirectorates = [];
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeChangePassword() {
      this.dialogChangePassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form1.reset();
      this.$refs.form1.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    refreshTable() {
      this.$axios
          .get("GetUsers", {noToast: true, failureToast: true})
          .then((response) => {
            this.users = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });

      this.$axios
          .get("GetGroups", {noToast: true})
          .then((response) => {
            this.groups = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });


      this.$axios
          .get("GetDirectorates", {noToast: true})
          .then((response) => {
            this.directorates = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });

      this.$axios
          .get("GetAllGovernorates", {noToast: true})
          .then((response) => {
            this.governorates = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });

      this.$axios
          .get("GetAllGroupschs", {noToast: true})
          .then((response) => {
            this.schoolsGroups = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });

      this.$axios
          .get("agencies", {noToast: true, params: {all: true}})
          .then((response) => {
            this.agencies = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        if (this.editedIndex > -1) {
          var updatedItem = this.users[this.editedIndex];

          updatedItem.name = this.editedItem.name;
          updatedItem.email = this.editedItem.email;
          updatedItem.groups_id = this.editedItem.groups_id;
          updatedItem.is_agent = this.editedItem.is_agent;
          updatedItem.agency_id = this.editedItem.agency_id;
          updatedItem.password_confirmation =
              this.editedItem.password_confirmation;
          updatedItem.user_directorates = this.selectedDirectorates;
          updatedItem.governorates = this.selectedGovernorates;
          updatedItem.school_group = this.selectedSchoolsGroups;
          this.$axios
              .post("UpdateUser/" + updatedItem.id, updatedItem, {failureToast: true})
              .then((response) => {
                if (response.data.status === "Successful") {
                  this.$store.state.userDirectorates = null;
                  this.$store.dispatch('GetUserDirectorates');
                  this.close();
                }

                this.$axios.get("GetGroups", {noToast: true}).then((response) => {
                  this.groups = response.data.data;
                });
              })
              .finally(() => {
                this.submitLoading = false;
                this.loading = false;
                this.refreshTable();
              });
        } else {
          this.editedItem.user_directorates = this.selectedDirectorates;
          this.editedItem.school_group = this.selectedSchoolsGroups;
          this.editedItem.governorates = this.selectedGovernorates;
          this.$axios
              .post("AddUser", this.editedItem)
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.close();
                }
              })
              .finally(() => {
                this.submitLoading = false;
                this.loading = false;
                this.refreshTable();
              });
        }
      }
    },
    saveChangePassword() {
      var val = this.$refs.form1.validate();
      if (val) {
        this.submitLoading = true;
        var updatedItem = this.users[this.editedIndex];
        var changePasswordModel = {
          id: updatedItem.id,
          password: this.editedItem.password,
          password_confirmation: this.editedItem.password_confirmation,
        };
        this.$axios
            .post(
                "ChangeUserPasswordByAdmin/" + changePasswordModel.id,
                changePasswordModel
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.closeChangePassword();
              }
            })
            .finally(() => {
              this.refreshTable();
              this.submitLoading = false;
            })
      }
    },
  },
};
</script>
